import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getFileExtension = (filename: string) => {
  try {
    return filename.split('.').pop()
  } catch (e) {
    return null
  }
}

export const isExternalUrl = (url: string) => {
  return url.startsWith('http')
}
